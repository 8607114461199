import React from "react";
import CartIcon from "assets/svgs/pages/index/additional-education/cart-icon.svg";
import WatchIcon from "assets/svgs/pages/index/additional-education/watch-icon.svg";
import DownloadIcon from "assets/svgs/pages/index/additional-education/download-icon.svg";

import * as SC from "./style";

const EducationCardComponent = ({ card }) => {
  const {
    buyLink,
    cardDescription,
    cardIconPrimary,
    cardIconSecondary,
    cardTitle,
    cardTitleSize,
    presentationLink,
    tags,
    vkVideoLink,
    youtubeVideoLink,
  } = card;
  return (
    <SC.StyledWrapperWithBorder>
      <SC.Logos $isFullWidth={!cardIconSecondary && !cardTitle}>
        {cardIconPrimary && (
          <SC.PrimaryIcon $isFullWidth={!cardIconSecondary && !cardTitle}>
            {cardIconPrimary}
          </SC.PrimaryIcon>
        )}
        {cardIconSecondary && (
          <SC.SecondaryIconWrapper>
            <SC.SecondaryIcon>{cardIconSecondary}</SC.SecondaryIcon>
          </SC.SecondaryIconWrapper>
        )}
        {cardTitle && (
          <SC.CardTitle $size={cardTitleSize}>{cardTitle}</SC.CardTitle>
        )}
      </SC.Logos>
      <SC.CardDescription>{cardDescription}</SC.CardDescription>
      <SC.ButtonsWrapper>
        {vkVideoLink && (
          <SC.StyledWatchButtonComponent
            asProp="a"
            href={vkVideoLink}
            rel="noopener noreferrer"
            icon={<WatchIcon />}
            target="_blank"
          >
            Смотреть (ВКВИ)
          </SC.StyledWatchButtonComponent>
        )}
        {youtubeVideoLink && (
          <SC.StyledWatchButtonComponent
            asProp="a"
            href={youtubeVideoLink}
            rel="noopener noreferrer"
            icon={<WatchIcon />}
            target="_blank"
          >
            Смотреть (Ютуб)
          </SC.StyledWatchButtonComponent>
        )}
        {presentationLink && (
          <>
            {!vkVideoLink && !youtubeVideoLink && !buyLink ? (
              <SC.StyledPresentationButtonComponent
                asProp="a"
                target="_blank"
                href={presentationLink}
                rel="noopener noreferrer"
                theme="red"
                icon={<DownloadIcon />}
                $isBig
              >
                Презентация (в Фигме)
              </SC.StyledPresentationButtonComponent>
            ) : (
              <SC.StyledPresentationButtonComponent
                asProp="a"
                target="_blank"
                href={presentationLink}
                rel="noopener noreferrer"
                theme="red"
                icon={<DownloadIcon />}
              >
                Преза (в Фигме)
              </SC.StyledPresentationButtonComponent>
            )}
          </>
        )}
        {buyLink && (
          <SC.StyledBuyButtonComponent
            asProp="a"
            href={buyLink}
            target="_blank"
            icon={<CartIcon />}
          >
            Прикупить
          </SC.StyledBuyButtonComponent>
        )}
      </SC.ButtonsWrapper>

      {!!tags.length && (
        <SC.TagsWrapper>
          {tags.map((tag, idx) => (
            <SC.CardTag key={idx}>{tag}</SC.CardTag>
          ))}
        </SC.TagsWrapper>
      )}
    </SC.StyledWrapperWithBorder>
  );
};

export default EducationCardComponent;
