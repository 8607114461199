import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

import FoundationPreview from "assets/svgs/pages/index/main-education/foundation-preview.svg";
import FoundationPreviewMobile from "assets/svgs/pages/index/main-education/foundation-preview_mobile.svg";
import FoundationFullLogo from "assets/svgs/pages/index/main-education/foundation-full-logo.svg";
import FoundationLogo from "assets/svgs/courses-order/foundation-of-graphic-design-v2-logo.svg";

import ButtonComponent from "components/layout/button";
import CourseTags from "components/pages/course-page/course-tags";

export const Section = styled.div`
  padding-top: 6vw;
  background: white;
  z-index: 2;
  position: relative;
`;

export const Title = styled.h1`
  font-family: Inter, sans-serif;
  font-weight: 900;
  font-size: 5.65vw;
  color: var(--granich-black);
  line-height: 0.89;
  letter-spacing: -0.15vw;
  position: absolute;
  top: 2.3vw;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  max-width: 30vw;
  margin: 0;

  @media only screen and (max-width: 575px) {
    width: 100%;
    font-size: 18vw;
    letter-spacing: -0.8vw;
    width: 100px;
    margin-bottom: 9.5vw;
    margin-top: 17vw;
    width: 100%;
    line-height: 0.9;
    position: relative;
    z-index: 2;
  }
`;

export const StyledFoundationMobileLogo = styled(FoundationLogo)`
  display: none;

  @media only screen and (max-width: 575px) {
    width: 32vw;
    display: block;
    margin: 0 auto;
    margin-bottom: 3.5vw;
    position: relative;
    top: -2vw;
    right: -2vw;
  }
`;

export const StyledFoundationPreview = styled(FoundationPreview)`
  width: 31vw;
  min-width: 31vw;
  margin-right: 4.7vw;

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const StyledFoundationPreviewMobile = styled(FoundationPreviewMobile)`
  display: none;

  @media only screen and (max-width: 575px) {
    display: block;
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 5vw;
  }
`;

export const FoundationPreviewWrapper = styled.div``;

export const StyledFoundationFullLogo = styled(FoundationFullLogo)`
  width: 31vw;
  min-width: 31vw;
  margin-right: 2vw;
  margin-bottom: 1.5vw;

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const Main = styled.div`
  display: flex;
  align-items: start;
  padding: 2.5vw 2.3vw 1.5vw;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    padding: 6vw;
  }
`;

export const CourseInfo = styled.div`
  position: relative;
  top: -0.5vw;
`;

export const AuthorImage = styled(GatsbyImage)`
  width: 8.2vw;
  min-width: 8.2vw;
  height: 8.2vw;
  border-radius: 50%;
  overflow: hidden;
  border: 0.25vw solid var(--granich-black);
  margin-right: 1vw;

  img {
    transform: scale(1.3) translateY(11%) !important;
  }

  @media only screen and (max-width: 575px) {
    width: 17vw;
    min-width: 17vw;
    height: 17vw;
    margin-right: 3vw;
    border-width: 0.8vw;
  }
`;

export const CourseTitle = styled.div`
  font-size: 3.4vw;
  font-weight: 500;
  line-height: 1;
  font-family: EB Garamond;
  font-style: italic;
  color: var(--granich-black);

  @media only screen and (max-width: 575px) {
    font-size: 7.3vw;
  }
`;

export const CourseDescr = styled.div`
  font-size: 1.65vw;
  line-height: 1.2;
  margin-bottom: 1.7vw;
  max-width: 98%;

  @media only screen and (max-width: 575px) {
    font-size: 5vw;
    padding-left: 2vw;
    max-width: 99%;
    line-height: 1.23;
    margin-bottom: 3.9vw;
  }
`;

export const CourseTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5vw;

  @media only screen and (max-width: 575px) {
    margin-bottom: 5vw;
  }
`;

export const CourseNavigation = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 575px) {
    flex-direction: column-reverse;
    align-items: start;
  }
`;

export const StyledButtonComponent = styled(ButtonComponent)`
  padding: 1.1vw 1.9vw;
  box-shadow: 0 0.5vw 2vw rgba(255, 209, 83, 0.5);
  margin-right: 2.9vw;
  span {
    font-size: 2.1vw;
    font-weight: 600;
  }

  svg {
    width: 100%;
    transform: scale(2) translateX(1%);
  }

  @media only screen and (max-width: 575px) {
    padding: 5.1vw 9.9vw;
    border-radius: 15vw;
    padding: 4.8vw 7.9vw;
    position: relative;
    left: 1vw;
    margin-bottom: 2vw;
    box-shadow: 0 1vw 5vw rgba(255, 209, 83, 0.5);
    span {
      font-size: 8vw;
    }
  }
`;

export const FoundationLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
  border: 0.15vw solid var(--granich-black);
  padding: 0.2vw;
  width: 3.4vw;
  min-width: 3.4vw;
  height: 3.4vw;

  @media only screen and (max-width: 575px) {
    width: 13vw;
    min-width: 13vw;
    height: 13vw;
    border-width: 0.8vw;
    padding: 1vw;
    margin-right: 2vw;

    svg {
      width: 4.5vw;
      height: 4.5vw;
      position: relative;
      right: -1vw;
    }
  }
`;

export const StyledFoundationLogo = styled(FoundationLogo)``;

export const StyledCourseTags = styled(CourseTags)`
  max-width: 17vw;

  @media only screen and (max-width: 575px) {
    max-width: 95%;
    margin-bottom: 3vw;
    padding-left: 1vw;

    div {
      font-size: 5.2vw;
      border-width: 1vw;
      border-radius: 10vw;
      padding: 0.7vw 2vw;
      margin-bottom: 2.3vw;
      font-weight: 600;
      letter-spacing: -0.1vw;
    }
  }
`;
