import React from "react";

// primary
import FigmaFast from "assets/svgs/pages/index/additional-education/primary/figma-fast.svg";
import Figma from "assets/svgs/pages/index/additional-education/primary/figma.svg";
import Midjourney from "assets/svgs/pages/index/additional-education/primary/midjourney.svg";
import ByHand from "assets/svgs/pages/index/additional-education/primary/grafdiz-by-hand.svg";
import Swiss from "assets/svgs/pages/index/additional-education/primary/graphic-system-swiss.svg";
import AiryLayout from "assets/svgs/pages/index/additional-education/primary/airy-layout.svg";
import GothicFuturism from "assets/svgs/pages/index/additional-education/primary/gothic-futurism.svg";
import NeoGeo from "assets/svgs/pages/index/additional-education/primary/neo-geo.svg";
import Ui from "assets/svgs/pages/index/additional-education/primary/ui.svg";
import Techno from "assets/svgs/pages/index/additional-education/primary/techno.svg";
import Constructivism from "assets/svgs/pages/index/additional-education/primary/constructivism.svg";
import Collage from "assets/svgs/pages/index/additional-education/primary/collage.svg";
import TypesOfFonts from "assets/svgs/pages/index/additional-education/primary/types-of-fonts.svg";
import Copyright from "assets/svgs/pages/index/additional-education/primary/copyright.svg";

// secondary
import AiryLayoutSecondary from "assets/svgs/pages/index/additional-education/secondary/airy-layout.svg";
import CollageSecondary from "assets/svgs/pages/index/additional-education/secondary/collage.svg";
import ConstructivismSecondary from "assets/svgs/pages/index/additional-education/secondary/constructivism.svg";
import GothicFuturismSecondary from "assets/svgs/pages/index/additional-education/secondary/gothic-futurism.svg";
import SwissSecondary from "assets/svgs/pages/index/additional-education/secondary/graphic-system-swiss.svg";
import NeoGeoSecondary from "assets/svgs/pages/index/additional-education/secondary/neo-geo.svg";
import TechnoSecondary from "assets/svgs/pages/index/additional-education/secondary/techno.svg";
import UiSecondary from "assets/svgs/pages/index/additional-education/secondary/ui.svg";

const useAdditionalEducationCards = () => [
  {
    cardIconPrimary: <FigmaFast />,
    cardIconSecondary: null,
    cardTitle: (
      <i>
        Фигма <span>Фаст</span>
      </i>
    ),
    cardTitleSize: "large",
    cardDescription: (
      <>
        Бесплатный мастер-класс по Фигме от Вадима Гранича. Чтобы быстро изучить
        Фигму <span>именно для графического дизайна.</span>
      </>
    ),
    vkVideoLink: "https://vk.com/video-164662407_456239222",
    youtubeVideoLink: "https://youtu.be/5mV6Pmbiu2A",
    presentationLink:
      "https://www.figma.com/community/file/1351467619735637677",
    buyLink: "",
    tags: ["Бесплатно"],
  },
  {
    cardIconPrimary: <Figma />,
    cardIconSecondary: null,
    cardTitle: "Granich Figma",
    cardTitleSize: "medium",
    cardDescription:
      "Платный мастер-класс по Фигме. Изучение продвинутых фич Фигмы, которые подходят для веб-дизайна и ui-дизайна.",
    vkVideoLink: "",
    youtubeVideoLink: "",
    presentationLink: "",
    buyLink: "/granich-figma",
    tags: [],
  },
  {
    cardIconPrimary: <Midjourney />,
    cardIconSecondary: null,
    cardTitle: "G-Midjourney",
    cardTitleSize: "medium",
    cardDescription: (
      <>
        Мастер-класс по нейросети Midjourney. Всё, что нужно знать для
        комфортной работы в этом сервисе. <i>Автор Алиса Брюква.</i>
      </>
    ),
    vkVideoLink: "",
    youtubeVideoLink: "",
    presentationLink: "",
    buyLink: "/granich-midjourney",
    tags: [],
  },
  {
    cardIconPrimary: <ByHand />,
    cardIconSecondary: null,
    cardTitleSize: "small",
    cardTitle: "Графдиз Руками",
    cardDescription: (
      <>
        Мастер-класс, чтобы научиться использовать физические материалы для
        создания макетов. <i>Автор Юлия Виноградова.</i>
      </>
    ),
    vkVideoLink: "",
    youtubeVideoLink: "",
    presentationLink: "",
    buyLink: "/graphic-design-by-hand",
    tags: [],
  },
  {
    cardIconPrimary: <Swiss />,
    cardIconSecondary: <SwissSecondary />,
    cardTitle: null,
    cardTitleSize: "medium",
    cardDescription:
      "Мастер-класс по швейцарской графической системе. Классика графического дизайна.",
    vkVideoLink: "",
    youtubeVideoLink: "",
    presentationLink: "",
    buyLink: "/graphic-system-swiss",
    tags: [],
  },
  {
    cardIconPrimary: <AiryLayout />,
    cardIconSecondary: <AiryLayoutSecondary />,
    cardTitle: null,
    cardTitleSize: "medium",
    cardDescription:
      "Мастер-класс по графической системе Воздух (Airy Layout). Для любителей минимализма.",
    vkVideoLink: "",
    youtubeVideoLink: "",
    presentationLink: "",
    buyLink: "/airy-layout",
    tags: [],
  },
  {
    cardIconPrimary: <GothicFuturism />,
    cardIconSecondary: <GothicFuturismSecondary />,
    cardTitle: null,
    cardTitleSize: "medium",
    cardDescription: (
      <>
        Бесплатный мастер-класс по графсистеме Готический Футуризм.{" "}
        <i>Автор Артём Ворошнин.</i>
      </>
    ),
    vkVideoLink: "",
    youtubeVideoLink: "",
    presentationLink:
      "https://www.figma.com/community/file/1409844857222414909",
    buyLink: "",
    tags: ["Бесплатно"],
  },
  {
    cardIconPrimary: <NeoGeo />,
    cardIconSecondary: <NeoGeoSecondary />,
    cardTitle: null,
    cardTitleSize: "medium",
    cardDescription:
      "Мастер-класс по графсистеме Нео‑Гео (Нео‑Геометрия). Для ценителей чистых форм.",
    vkVideoLink: "",
    youtubeVideoLink: "",
    presentationLink: "",
    buyLink: "/neo-geo",
    tags: [],
  },
  {
    cardIconPrimary: <Ui />,
    cardIconSecondary: <UiSecondary />,
    cardTitle: null,
    cardTitleSize: "medium",
    cardDescription: (
      <>
        Мастер-класс по графсистеме UI (User Interface).{" "}
        <span>Только для графических дизайнеров.</span>
      </>
    ),
    vkVideoLink: "",
    youtubeVideoLink: "",
    presentationLink: "",
    buyLink: "/ui",
    tags: [],
  },
  {
    cardIconPrimary: <Techno />,
    cardIconSecondary: <TechnoSecondary />,
    cardTitle: null,
    cardTitleSize: "medium",
    cardDescription:
      "Мастер-класс по графсистеме Техно. Для любителей техно‑музыки и подполья.",
    vkVideoLink: "",
    youtubeVideoLink: "",
    presentationLink: "",
    buyLink: "/techno",
    tags: [],
  },
  {
    cardIconPrimary: <Constructivism />,
    cardIconSecondary: <ConstructivismSecondary />,
    cardTitle: null,
    cardTitleSize: "medium",
    cardDescription:
      "Мастер-класс по графсистеме Конструктивизм. Для тех кто скучает по советскому.",
    vkVideoLink: "",
    youtubeVideoLink: "",
    presentationLink: "",
    buyLink: "/constructivism",
    tags: [],
  },
  {
    cardIconPrimary: <Collage />,
    cardIconSecondary: <CollageSecondary />,
    cardTitle: null,
    cardTitleSize: "medium",
    cardDescription:
      "Мастер-класс по графсистеме Коллаж. Для тех кто любит кромсать бумажки.",
    vkVideoLink: "",
    youtubeVideoLink: "",
    presentationLink: "",
    buyLink: "/collage",
    tags: [],
  },
  {
    cardIconPrimary: <Copyright />,
    cardIconSecondary: null,
    cardTitle: "Графдиз Копирайт",
    cardTitleSize: "small",
    cardDescription:
      "Мастер-класс, основы маркетинга для графичесчких дизайнеров. На примере коммерческого плаката.",
    vkVideoLink: "",
    youtubeVideoLink: "",
    presentationLink: "",
    buyLink: "/graphic-design-copywriting",
    tags: [],
  },
  {
    cardIconPrimary: <TypesOfFonts />,
    cardIconSecondary: null,
    cardTitle: null,
    cardTitleSize: "medium",
    cardDescription:
      "Легендарный урок Гранича по видам шрифтов. Гротески. Антиквы. Бруски. Фишки форм.",
    vkVideoLink: "https://vk.com/video-164662407_456239285",
    youtubeVideoLink: "https://youtu.be/Z7ylv7weSoY",
    presentationLink:
      "https://www.figma.com/community/file/1385169250518551695",
    buyLink: "",
    tags: ["Бесплатно"],
  },
];

export default useAdditionalEducationCards;
