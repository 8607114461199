import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container } from "components/style";
import * as SC from "./style";
import manifestRules from "./data";

const Author = () => {
  const data = useStaticQuery(graphql`
    query authorImage {
      placeholderImage: file(relativePath: { eq: "author.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, quality: 90, layout: CONSTRAINED)
        }
      }
    }
  `);

  const imageData = data.placeholderImage.childImageSharp.gatsbyImageData;

  return (
    <SC.Author>
      <SC.AuthorImage
        imgStyle={{ borderRadius: "50%" }}
        image={imageData}
        style={{ backgroundSize: "cover" }}
        alt="vadim-granich"
      />
      <SC.AuthorInfoWrapper>
        <SC.AuthorName>Вадим Гранич</SC.AuthorName>
        <SC.AuthorText>Создатель Школы, автор курсов</SC.AuthorText>
      </SC.AuthorInfoWrapper>
    </SC.Author>
  );
};

const Manifest = () => (
  <SC.Section id="manifest">
    <Container>
      <SC.Wrapper>
        <SC.InfoTitle>
          Осознанно развиваем твёрдые навыки дизайнеров
        </SC.InfoTitle>
        <SC.SecondaryWrapper>
          <Author />
          <SC.Info>
            <SC.InfoText>
              В нашу Школу приходят, чтобы активно развивать в себе твёрдые
              навыки. Мы за то, чтобы вам было тяжело, но конечная награда того
              стоила. Здесь вы ученики, а не клиенты. Поэтому, наши курсы:
            </SC.InfoText>
            <SC.InfoRules>
              {manifestRules.map(({ title, text }, idx) => (
                <SC.Rule key={idx + 1}>
                  <SC.RuleTitle>{title}</SC.RuleTitle>
                  {text}
                  <SC.RuleNumber>{idx + 1}</SC.RuleNumber>
                </SC.Rule>
              ))}
            </SC.InfoRules>
          </SC.Info>
        </SC.SecondaryWrapper>
      </SC.Wrapper>
    </Container>
  </SC.Section>
);
export default Manifest;
