module.exports = [
  {
    title: "Выточены —",
    text: "Вся информация на курсах очищена от лишнего, грамотно подана и структурирована",
  },
  {
    title: "Интенсивны —",
    text: "Курсы занимают много времени, акцент на практику (смотрите фильм Одержимость)",
  },
  {
    title: "С дедлайнами —",
    text: "Не получится просто взять курс и забыть о нем. Дедлайны будут постоянно вас толкать вперёд",
  },
];
