import React from "react";
import { Container } from "components/style";
import VKIcon from "assets/svgs/granich-main-team/vk.svg";
import InstagramIcon from "assets/svgs/granich-main-team/instagram.svg";
import TelegramIcon from "assets/svgs/granich-main-team/telegram.svg";
import PinterestIcon from "assets/svgs/granich-main-team/pinterest.svg";
import YoutubeIcon from "assets/svgs/youtube_black.svg";
import * as SC from "./style";

const Blog = () => (
  <SC.Section id="blog">
    <Container>
      <SC.Wrapper>
        <SC.Title>Блог Школы</SC.Title>
        <SC.Text>
          Оповещения о наборах на курсы, работы учеников и изредка посты
          о теории дизайна:
        </SC.Text>
        <SC.Socials>
          <SC.Social>
            <InstagramIcon />
            <a
              href="https://www.instagram.com/granichgram"
              rel="noopener noreferrer"
              target="_blank"
            >
              @granichgram
            </a>
          </SC.Social>
          <SC.Social>
            <TelegramIcon />
            <a
              href="https://t.me/granichannel"
              rel="noopener noreferrer"
              target="_blank"
            >
              t.me/granichannel
            </a>
          </SC.Social>
          <SC.Social>
            <VKIcon />
            <a
              href="https://vk.com/granichvk"
              rel="noopener noreferrer"
              target="_blank"
            >
              vk.com/granichvk
            </a>
          </SC.Social>
        </SC.Socials>
        <SC.Devider />
        <SC.Text>
          На ютубе Школы вы найдёте уроки по графдизайну и сможете оценить наш
          подход:
        </SC.Text>
        <SC.Socials>
          <SC.Social youtube>
            <YoutubeIcon />
            <a
              href="https://www.youtube.com/channel/UCCyKQE_YbC1fO96Hw9XXHPQ"
              rel="noopener noreferrer"
              target="_blank"
            >
              youtube.com/c/granich
            </a>
          </SC.Social>
        </SC.Socials>
        <SC.Devider />
        <SC.Text>
          Энциклопедия Графдизайна. Кладезь референсов
          для графических дизайнеров:
        </SC.Text>

        <SC.Socials>
          <SC.Social>
            <PinterestIcon />
            <a
              href="https://pin.it/2IdVLUy"
              rel="noopener noreferrer"
              target="_blank"
            >
              pinterest.ru/vadim_granich
            </a>
          </SC.Social>
        </SC.Socials>
      </SC.Wrapper>
    </Container>
  </SC.Section>
);

export default Blog;
