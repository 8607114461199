import styled from "styled-components";

export const Section = styled.section``;

export const Wrapper = styled.div`
  background: #e8e8e8;
  border-radius: 0.6vw;
  padding: 1.2vw 2vw;
  font-size: 1.15vw;
  color: var(--granich-grey);
  color: #666666;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 575px) {
    font-size: 3.8vw;
    border-radius: 2.5vw;
    padding: 4vw 0;
    line-height: 1.25;
    flex-direction: column;
  }
`;
