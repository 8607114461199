import React from "react";

import { Container } from "components/style";

import * as SC from "./style";
import useAdditionalEducationCards from "./useAdditionalEducationCards";
import EducationCardComponent from "./EducationCardComponent";

const AdditionalEducation = ({ courses }) => {
  const cards = useAdditionalEducationCards();
  return (
    <SC.Section id="courses">
      <Container>
        <SC.StyledSectionSubTitleNew>
          Доп<SC.SectionDotStyle>.</SC.SectionDotStyle> обучение
        </SC.StyledSectionSubTitleNew>
        {cards.map((card, idx) => (
          <EducationCardComponent card={card} key={idx} />
        ))}
      </Container>
    </SC.Section>
  );
};

export default AdditionalEducation;
