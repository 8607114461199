import React from "react";
import { Container } from "components/style";
import * as SC from "./style";

const BlockWithVideoComponent = ({ title, description, youtubeUrl }) => (
  <SC.Section>
    <Container>
      <SC.Wrapper>
        <SC.VideoSection>
          <SC.VideoSectionContent>
            <SC.LogosWrapper>
              <SC.StyledDesignProsmotrLogo />
              <SC.Cross>+</SC.Cross>
              <SC.StyledGranichLogo />
            </SC.LogosWrapper>
            {title && <SC.VideoSectionTitle>{title}</SC.VideoSectionTitle>}
            <SC.VideoSectionDescr>{description}</SC.VideoSectionDescr>
          </SC.VideoSectionContent>
          <SC.YoutubeBlock>
            <iframe
              title="youtubeFrame"
              width="100%"
              src={youtubeUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </SC.YoutubeBlock>
        </SC.VideoSection>
      </SC.Wrapper>
    </Container>
  </SC.Section>
);

export default BlockWithVideoComponent;
