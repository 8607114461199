import React from "react";

import { StyledLogInIcon } from "components/layout/header/style";

import { Container, WrapperWithBorder } from "components/style";

import * as SC from "./style";

const Offer = () => (
  <SC.Section id="offer">
    <Container>
      <WrapperWithBorder>
        <SC.Wrapper>
          <SC.Title>Школа Гранича</SC.Title>
          <SC.Descr>
            Осознанно обучаем графдизайну — и точка<span>.</span>
          </SC.Descr>
          <SC.LogInBlock>
            <SC.LogInText>
              Вход в кабинет <span>(для учеников)</span>
            </SC.LogInText>
            <SC.StyledButtonComponent
              asProp="a"
              rel="noopener noreferrer"
              href="https://school.granich.design/cms/system/login"
              target="_blank"
              theme="red"
              icon={<StyledLogInIcon />}
            >
              <span>Войти</span>
            </SC.StyledButtonComponent>
          </SC.LogInBlock>
          <SC.StyledStatueLeft />
          <SC.StyledStatueRight />
          <SC.StyledBuba />
          <SC.StyledFigmaCloud />
          <SC.StyledFigmaLogo />
          <SC.StyledFlagJapan />
          <SC.StyledFlagSwiss />
          <SC.StyledGranichHeartKPop />
          <SC.StyledKiki />
          <SC.StyledStar />
          <SC.StyledTelegramAirplane />
          <SC.StyledVkVideo />
          <SC.StyledYoutube />
          <SC.ArrowAnimation>
            <SC.ScrollDownText>Неученики скролльте ниже</SC.ScrollDownText>
            <SC.ScrollDownWrapper>
              <SC.StyledArrowDown />
              <SC.StyledArrowBorder />
            </SC.ScrollDownWrapper>
          </SC.ArrowAnimation>
        </SC.Wrapper>
      </WrapperWithBorder>
    </Container>
  </SC.Section>
);

export default Offer;
