import React from "react";

import Button from "./style";

const ButtonComponent = ({
  icon,
  children,
  theme,
  asProp,
  href,
  className,
  ...props
}) => (
  <Button
    className={className}
    theme={theme}
    as={asProp}
    href={href}
    {...props}
  >
    {icon}
    <span>{children}</span>
  </Button>
);

export default ButtonComponent;
