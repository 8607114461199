import React from "react";
import { getCourseStatus } from "utilities/course-utils";
import * as SC from "./style";

const CourseTags = ({ course, className, withCourseType = true }) => {
  const tags = course.body.find(el => el.slice_type === "tags").items;

  return (
    <SC.CourseTagsWrapper className={className}>
      <SC.StyledCourseStatusTag courseStatus={getCourseStatus(course)}>
        {getCourseStatus(course)}
      </SC.StyledCourseStatusTag>
      {withCourseType && <SC.StyledTag>{course.coursetype}</SC.StyledTag>}
      <>
        {course.coursestream && (
          <SC.StyledTag>{course.coursestream} поток</SC.StyledTag>
        )}
        {course.coursestartandend && (
          <SC.StyledTag>{course.coursestartandend.text}</SC.StyledTag>
        )}
      </>
      {tags.map(({ coursetag: { text } }, idx) => (
        <SC.StyledTag key={idx}>{text}</SC.StyledTag>
      ))}
    </SC.CourseTagsWrapper>
  );
};

export default CourseTags;
