import React from "react";
import SocialIcons from "components/common/social-icons";
import { Container } from "components/style";
import * as SC from "./style";

const EducationQuestions = () => (
  <SC.Section>
    <Container>
      <SC.Wrapper>
        Вопросы по обучению пишите: <SocialIcons />
      </SC.Wrapper>
    </Container>
  </SC.Section>
);

export default EducationQuestions;
