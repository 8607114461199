import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex-inline;
  align-items: center;
`;

export const Email = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0.2vw;
  :hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 575px) {
    margin: 0 0.5vw;
  }
`;

export const SocialButtons = styled.div`
  display: inline-flex;
  margin: 0 0.5vw;

  svg {
    width: 2vw;
    min-width: 2vw;
    transition: transform 0.2s ease;

    :hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  @media only screen and (max-width: 575px) {
    display: flex;
    margin-top: 2vw;

    svg {
      width: 7vw;
      min-width: 7vw;
    }
  }
`;

export const SocialLink = styled.a`
  margin: 0 0.2vw;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 575px) {
    margin: 0 0.5vw;
  }
`;
