// react/gatsby imports
import React from "react";
// components
import Manifest from "components/pages/index/manifest";
import Layout from "components/layout";
import SEO from "components/layout/seo";
import Offer from "components/pages/index/offer";
import Mailing from "components/common/mailing";
import OurTeam from "components/pages/index/team";
import EducationQuestions from "components/pages/course-page/education-questions";
import BlockWithVideoComponent from "components/common/block-with-video";
import Blog from "components/pages/blog";
import MainEducation from "components/pages/index/main-education";
import AdditionalEducation from "components/pages/index/additional-education";

// data
import teamData from "data/team";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// hooks
import useIndexQuery from "hooks/graphql/useIndexQuery";
import PageTransitionGradient from "components/pages/index/page-transition-gradient/style";

const IndexPage = () => {
  const prismicData = useIndexQuery();
  const { data: seoData } = prismicData.page || {};

  const filteredTeam = teamData.filter(({ name }) => name !== "Саша Буяк");

  return (
    <Layout headerType="light">
      <SEO
        title="Школа Гранича"
        description={
          seoData?.seodescription?.text ||
          "Осознанно обучаем графдизайну — и точка."
        }
        keywords={[
          "школа",
          "осознанность",
          "фриланс",
          "графический",
          "дизайн",
          "granich",
          "гранич",
          "графдизайн",
          "курсы",
        ]}
        ogImage={seoData?.seoimage?.url}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url}
        url={generateCanonicalLink()}
      />
      <Offer />
      <MainEducation courses={prismicData?.courses.nodes} />
      <AdditionalEducation courses={prismicData?.courses.nodes} />
      <PageTransitionGradient />
      <EducationQuestions />
      <Manifest />
      <BlockWithVideoComponent
        title="Осознанный дизайнер"
        description="Об осознанном подходе Школы, и в целом в дизайне, Вадим Гранич более подробно рассказывает на лекции Дизайн Просмотра"
        youtubeUrl="https://www.youtube.com/embed/jOgN14KpJ-8"
      />
      <Mailing />
      <Blog />
      <div style={{ height: "0.2vw", marginTop: "0.5vw" }} id="team" />
      <OurTeam data={filteredTeam} />
    </Layout>
  );
};

export default IndexPage;
