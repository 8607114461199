import styled from "styled-components";
import DesignProsmotrLogo from "assets/svgs/design-prosmotr.svg";
import GranichLogo from "assets/svgs/granich-new-black.svg";

export const Section = styled.section`
  margin-top: 2vw;
  margin-bottom: 2vw;

  @media only screen and (max-width: 575px) {
    margin-top: 4vw;
    margin-bottom: 4vw;
  }
`;

export const Wrapper = styled.div`
  background: white;
  border-radius: 0.6vw;
  padding: 3vw;

  @media only screen and (max-width: 575px) {
    padding: 6vw;
    border-radius: 2.5vw;
  }
`;

export const Title = styled.h1`
  font-size: 6.9vw;
  font-weight: 500;
  font-family: EB Garamond;
  font-style: italic;
  letter-spacing: -0.1rem;
  line-height: 1;
  margin: 0;
  margin-bottom: 3vw;
  text-align: center;
  span {
    font-family: Inter;
    font-weight: bold;
    font-style: normal;
    font-size: 6.5vw;
    letter-spacing: -0.1rem;
    display: block;
  }
  @media only screen and (max-width: 575px) {
    font-size: 11vw;
    letter-spacing: -0.1rem;
    margin-bottom: 6vw;
    span {
      font-size: 11vw;
      letter-spacing: -0.05rem;
    }
  }
`;

export const Header = styled.div`
  margin-bottom: 5vw;
`;

export const HeaderDescr = styled.p`
  font-size: 1.45vw;
  font-weight: 500;
  margin-bottom: 3vw;
  padding: 0 5vw;
  text-align: center;
  line-height: 1.5;
  @media only screen and (max-width: 575px) {
    font-size: 4.6vw;
    margin-bottom: 0;
    line-height: 1.5;
  }
`;

export const VideoSection = styled.div`
  display: flex;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

export const VideoSectionTitle = styled.h2`
  color: var(--granich-black);
  font-size: 3.8vw;
  letter-spacing: -0.1vw;
  font-weight: 500;
  line-height: 1;
  margin-top: -1vw;
  margin-bottom: 1.5vw;
  line-height: 1.05;
  font-family: Inter;
  font-weight: 800;
  @media only screen and (max-width: 575px) {
    font-size: 10.5vw;
    white-space: normal;
    margin-bottom: 5vw;
    line-height: 1;
  }
`;

export const VideoSectionDescr = styled.div`
  font-size: 1.3vw;
  font-weight: 500;
  line-height: 1.5;

  @media only screen and (max-width: 575px) {
    font-size: 4.6vw;
    margin-bottom: 5vw;
    line-height: 1.5;
    max-width: 100%;
    margin-left: 0;
  }
`;

export const YoutubeBlock = styled.div`
  position: relative;
  width: 100%;
  height: 21vw;

  iframe {
    border: 2px solid rgba(0, 0, 0, 0.2);
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
  }
  @media only screen and (max-width: 575px) {
    padding: 0;
    height: auto;
    iframe {
      position: relative;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 43vw;
    }
  }
`;

export const VideoSectionContent = styled.div.attrs({
  className: "metaphors-video-section-content",
})`
  max-width: 45%;
  margin-right: 2vw;
  @media only screen and (max-width: 575px) {
    max-width: 100%;
    margin-right: 0;
  }
`;

export const StyledGranichLogo = styled(GranichLogo)`
  width: 4vw;

  @media only screen and (max-width: 575px) {
    width: 12vw;
  }
`;

export const StyledDesignProsmotrLogo = styled(DesignProsmotrLogo)`
  width: 13vw;
  margin-right: 1vw;
  position: relative;
  fill: var(--granich-black);

  @media only screen and (max-width: 575px) {
    width: 40vw;
    margin-right: 3vw;
  }
`;

export const LogosWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2vw;

  @media only screen and (max-width: 575px) {
    margin-bottom: 7vw;
  }
`;

export const Cross = styled.span`
  transform: rotate(45deg);
  font-size: 3vw;
  color: var(--granich-black);
  font-weight: 400;
  display: block;
  margin-right: 1vw;
  display: flex;
  align-items: center;
  max-height: 2vw;

  @media only screen and (max-width: 575px) {
    margin-right: 3vw;
    font-size: 10vw;
    max-height: 7vw;
  }
`;
