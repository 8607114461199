import React from "react";
import TelegramIcon from "assets/svgs/social-side-icons/greyscale/social-side-icon-telegram.svg";
import InstagramIcon from "assets/svgs/social-side-icons/greyscale/social-side-icon-instagram.svg";
import EmailIcon from "assets/svgs/social-side-icons/greyscale/social-side-icon-email.svg";
import VKIcon from "assets/svgs/social-side-icons/greyscale/social-side-icon-vk.svg";
import CopyMailToClipboardComponent from "components/common/copy-to-clipboard";
import * as SC from "./style";

const SocialIcons = () => (
  <SC.Wrapper>
    <SC.SocialButtons>
      <CopyMailToClipboardComponent>
        <SC.Email>
          <EmailIcon />
        </SC.Email>
      </CopyMailToClipboardComponent>
      <SC.SocialLink target="_blank" href="https://t.me/granich_school">
        <TelegramIcon />
      </SC.SocialLink>
      <SC.SocialLink
        target="_blank"
        href="https://www.instagram.com/granichgram"
      >
        <InstagramIcon />
      </SC.SocialLink>
      <SC.SocialLink target="_blank" href="https://vk.com/write-164662407">
        <VKIcon />
      </SC.SocialLink>
    </SC.SocialButtons>
  </SC.Wrapper>
);

export default SocialIcons;
