// react/gatsby imports
import { useStaticQuery, graphql } from "gatsby";
// при создании слайсов нужно в JSON едиторе прописать им тип, чтобы он отображался в graphql https://s.mail.ru/agnB/huJueyBkH https://s.mail.ru/h38a/uMxFbWKm8
const useIndexQuery = () => {
  const prismicData = useStaticQuery(graphql`
    query indexQuery {
      courses: allPrismicCoursetype(
        sort: { fields: data___courseordernumber, order: ASC }
      ) {
        nodes {
          uid
          id
          data {
            body {
              ... on PrismicCoursetypeDataBodyTags {
                id
                slice_type
                items {
                  coursetag {
                    text
                  }
                }
              }
              ... on PrismicCoursetypeDataBodyCourseCreators {
                slice_type
                items {
                  coursecreator {
                    document {
                      ... on PrismicTeammembertype {
                        id
                        data {
                          teammemberimage {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            courseordernumber
            coursetitle {
              text
            }
            courseadditionaltype {
              text
            }
            coursebuttontext {
              text
            }
            coursedescription {
              text
            }
            coursedevelopment
            courseduration {
              text
            }
            courseempty
            courseexternallink {
              url
            }
            coursepolicy {
              text
            }
            coursepreview {
              gatsbyImageData
            }
            courseprice
            coursestart {
              text
            }
            coursestartandend {
              text
            }
            coursestatus
            coursestream
            coursetype
          }
        }
      }
      content: allPrismicContent(
        sort: { fields: data___contentordernumber, order: ASC }
      ) {
        nodes {
          uid
          id
          data {
            body {
              ... on PrismicContentDataBodyTags {
                id
                slice_type
                items {
                  contenttag {
                    text
                  }
                }
              }
            }
            contentordernumber
            contenttype
            contenttitle {
              text
            }
            contentdescription {
              text
            }
            contentimage {
              gatsbyImageData
            }
            contentyoutubevideolink {
              url
            }
            contentlinkpinterest {
              url
            }
            contentlinkbehance {
              url
            }
            contentlinkmedium {
              url
            }
            contentpdf {
              url
            }
            contentyoutubetiming {
              text
            }
          }
        }
      }
      page: prismicPage(uid: { eq: "index" }) {
        uid
        data {
          seodescription {
            text
          }
          seoimage {
            url(imgixParams: { width: 1200 })
            thumbnails {
              vk {
                url(imgixParams: { width: 510 })
              }
            }
          }
        }
      }
    }
  `);

  return prismicData;
};

export default useIndexQuery;
