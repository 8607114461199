import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Container } from "components/style";
import * as SC from "./style";
import TeamMember from "./teacher";

const OurTeam = ({ data }) => (
  <SC.Section>
    <Container>
      <SC.CategoryTitle>Команда</SC.CategoryTitle>
      <SC.Wrapper>
        {data.map(teamMember => (
          <TeamMember key={uuidv4()} teamMember={teamMember} />
        ))}
      </SC.Wrapper>
    </Container>
  </SC.Section>
);

export default OurTeam;
