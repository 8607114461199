import styled from "styled-components";

const Button = styled.button`
  display: flex;
  align-items: center;
  background: ${({ theme }) =>
      theme === "red"
        ? `
    var(--granich-red-vertical-gradient); 
  `
        : `
  var(--granich-grey-gradient);
  `}
    span {
    display: block;
    margin-left: 0.5vw;
    color: white;
    font-family: Inter;
    font-size: 1vw;
    font-weight: 600;
  }

  border: unset;
  padding: 0.8vw;
  border-radius: 5vw;
  margin-right: 0.8vw;
  line-height: 1;
  white-space: nowrap;

  :hover {
    color: white;
    font-size: 1vw;
    font-weight: 600;
    cursor: pointer;
    border-color: transparent;
    background: ${({ theme }) =>
      theme === "red"
        ? `
      var(--granich-red_button-hover); 
    `
        : `
        rgba(150, 150, 150, 1);
    `};
  }

  :last-child {
    margin-right: 0vw;
  }

  svg {
    width: 1.2vw;
    height: 1.2vw;
    min-width: 1.2vw;
  }

  @media only screen and (max-width: 575px) {
    padding: 2.3vw 2.6vw;
    border-radius: 10vw;
    margin-right: 4.5vw;

    :nth-child(4) {
      margin-right: 0vw;
    }

    span {
      font-size: 3.8vw;
    }

    svg {
      width: 5.6vw;
      height: 5.6vw;
      min-width: 5.8vw;
      transform: scale(1.1);
      margin-right: 0.8vw;
    }

    ${({ theme }) =>
      theme !== "red" &&
      `
      border-width: 0.5vw;
    `}
  }
`;

export default Button;
