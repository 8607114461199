import styled from "styled-components";

const PageTransitionGradient = styled.div`
  height: 10vw;
  background: linear-gradient(white 0%, var(--granich-background-color) 100%);
  position: relative;
  margin-top: -10vw;
  z-index: 0;

  @media only screen and (max-width: 575px) {
    height: 13vw;
  }
`;

export default PageTransitionGradient;
