import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import {
  Container,
  SectionSubTitleNew,
  WrapperWithBorder,
} from "components/style";

import * as SC from "./style";

const MainEducation = ({ courses }) => {
  const foundationCourse = courses.find(
    course => course?.data?.coursetitle?.text === "Фундамент Графдизайна"
  );

  const authorImage = useStaticQuery(graphql`
    query authorFoundationImage {
      placeholderImage: file(relativePath: { eq: "author.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 350, quality: 90, layout: CONSTRAINED)
        }
      }
    }
  `);

  const imageData =
    authorImage.placeholderImage.childImageSharp.gatsbyImageData;

  return (
    <SC.Section id="courses">
      <Container>
        <SectionSubTitleNew>Основное обучение</SectionSubTitleNew>
        <WrapperWithBorder>
          <SC.Main>
            <SC.FoundationPreviewWrapper>
              <SC.StyledFoundationMobileLogo />
              <SC.StyledFoundationPreviewMobile />
              <SC.StyledFoundationFullLogo />
              <SC.StyledFoundationPreview />
            </SC.FoundationPreviewWrapper>

            <SC.CourseInfo>
              <SC.CourseTitleWrapper>
                <SC.AuthorImage
                  imgStyle={{ borderRadius: "50%" }}
                  image={imageData}
                  style={{ backgroundSize: "cover" }}
                  alt="vadim-granich"
                />
                <SC.CourseTitle>Авторский курс Вадима Гранича</SC.CourseTitle>
              </SC.CourseTitleWrapper>
              <SC.CourseDescr>
                Интенсивный курс графического дизайна. Обучение композициям.
                Графическим системам. И продвинутым инструментам графдизайна.
              </SC.CourseDescr>
              <SC.CourseNavigation>
                <SC.StyledButtonComponent
                  icon={
                    <SC.FoundationLogoWrapper>
                      <SC.StyledFoundationLogo />
                    </SC.FoundationLogoWrapper>
                  }
                  href="/foundation-of-graphic-design"
                  target="_blank"
                  asProp="a"
                  theme="red"
                >
                  Узнать
                </SC.StyledButtonComponent>
                <SC.StyledCourseTags
                  course={foundationCourse.data}
                  withCourseType={false}
                />
              </SC.CourseNavigation>
            </SC.CourseInfo>
          </SC.Main>
        </WrapperWithBorder>
      </Container>
    </SC.Section>
  );
};

export default MainEducation;
