import React from "react";
import styled, { css } from "styled-components";
import COURSE_STATUSES from "constants/course-statuses";

const styleConstants = {
  columnsGap: ["10vw", "3vw"],
  courseIconWidth: ["15vw", "3.1vw"],
  courseIconMarginRight: ["2.5vw", "0.7vw"],
  boxShadow: [
    "rgba(0, 0, 0, 0.2) 0px 0.3vw 3vw",
    "rgba(0, 0, 0, 0.2) 0px 0.1vw 0.6vw",
  ],
  courseIconBorderWidth: ["1.5vw", "0.3vw"],
  courseIconBorderRadius: ["4vw", "0.9vw"],
  courseBorderRadius: ["5vw", "1.2vw"],
  baseTagBackground: "rgba(0,0,0,0.25)",
  tagBorderRadius: ["1.5vw", "2vw"],
  tagMargin: ["1.5vw", "0.5vw"],
  tagFontSize: ["4vw", "1.25vw"],
  blockBorderRadius: ["4vw", "0.5vw"],
  blockBaseBorderWidth: ["1.2vw", "0.25vw"],
};

const CourseTagBaseStyle = css`
  padding: 0.15vw ${styleConstants.tagMargin[1]};
  margin-right: ${styleConstants.tagMargin[1]};
  margin-bottom: ${styleConstants.tagMargin[1]};
  font-size: ${styleConstants.tagFontSize[1]};
  border-radius: ${styleConstants.tagBorderRadius[1]};
  border: 0.25vw solid var(--granich-black);
  color: var(--granich-black);
  display: flex;
  align-items: center;
  white-space: nowrap;
  letter-spacing: -0.01vw;
  font-weight: 600;

  :last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 575px) {
    font-size: ${styleConstants.tagFontSize[0]};
    padding: 0.5vw ${styleConstants.tagMargin[0]};
    margin-right: ${styleConstants.tagMargin[0]};
    margin-bottom: ${styleConstants.tagMargin[0]};
    border-radius: ${styleConstants.tagBorderRadius[0]};
  }
`;

export const CourseStatusTag = styled.div`
  ${CourseTagBaseStyle};
  position: relative;
  ${({ courseStatus }) => {
    switch (courseStatus) {
      case COURSE_STATUSES.development:
      case COURSE_STATUSES.closed:
        return css`
          border-color: rgb(228, 5, 33);
          color: rgb(228, 5, 33);
        `;
      case COURSE_STATUSES.secret:
        return css`
          border-color: var(--granich-black);
          color: var(--granich-black);
        `;
      case COURSE_STATUSES.open:
      default:
        return css`
          border-color: var(--granich-green);
          color: var(--granich-green);
        `;
    }
  }};
  svg {
    fill: white;
    width: 0.9vw;
    margin-right: 0.2vw;
    position: relative;
    top: -0.05vw;
  }

  @media only screen and (max-width: 575px) {
    svg {
      width: 3.5vw;
      margin-right: 1.2vw;
    }
  }
`;

export const CourseTag = styled.div.attrs(() => ({
  className: "courses-order-course-tag",
}))`
  ${CourseTagBaseStyle};
`;

export const CourseSecondaryTag = styled.div.attrs(() => ({
  className: "courses-order-course-secondary-tag",
}))`
  ${CourseTagBaseStyle};
  background: var(--granich-black);
`;

export const StyledTag = styled(CourseTag)`
  font-size: 1.25vw;
  @media only screen and (max-width: 575px) {
    font-size: 3.7vw;
  }
`;

export const StyledCourseStatusTag = styled(props => (
  <CourseStatusTag {...props} />
))`
  @media only screen and (max-width: 575px) {
    font-size: 3.7vw;

    svg {
      transform: scale(1.1);
      width: 3.2vw;
      min-width: 3.2vw;
      height: 3.2vw;
    }
  }
`;

export const CourseTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledSecondaryTag = styled(CourseSecondaryTag)`
  padding: 0.3vw 0.4vw;
  @media only screen and (max-width: 575px) {
    padding: 0.5vw 1.5vw;
  }
`;
