import React from "react";
import COURSE_STATUSES from "constants/course-statuses";
import CrossIcon from "assets/svgs/cross-inter-medium.svg";
import CheckIcon from "assets/svgs/check-inter-medium.svg";
import WarningIcon from "assets/svgs/warning-inter-medium.svg";
import IncognitoIcon from "assets/svgs/incognito.svg";

export const getCourseStatus = course => {
  switch (true) {
    case course?.coursedevelopment:
      return COURSE_STATUSES.development;
    case course?.coursestatus:
      return COURSE_STATUSES.open;
    default:
      return COURSE_STATUSES.closed;
  }
};

export const getCourseStatusIcon = status => {
  switch (status) {
    case COURSE_STATUSES.secret:
      return <IncognitoIcon />;
    case COURSE_STATUSES.closed:
      return <CrossIcon />;
    case COURSE_STATUSES.development:
      return <WarningIcon />;
    case COURSE_STATUSES.open:
    case COURSE_STATUSES.free:
    default:
      return <CheckIcon />;
  }
};
